@import '../../styles/variables';

.row {
  display: grid;
  max-width: var(--max-width-page);
  margin: 0 auto;
  gap: var(--spacing-2);
  text-align: left;
  width: 100%;

  > div {
    container: content / inline-size;
  }
}

.withNav {
  display: grid;
  max-width: var(--max-width-page);
  margin: 0 auto;
  gap: var(--spacing-2);
  text-align: left;
  width: 100%;

  > nav {
    text-align: left;
    display: none; // Hidden on mobile

    ul {
      list-style: none;
    }

    ul ul {
      padding-left: 1.6rem;
    }

    a {
      color: var(--color-secondary-1);
      display: block;
      padding: 0.8rem 0;
      font-size: 1.5rem;

      &.active {
        color: var(--color-primary-1);
      }

      &:hover {
        color: var(--color-primary-1);
      }
    }
  }

  &.withNavOffset > nav {
    margin-top: 5.8rem;
  }
}

@media only screen and (width >= $tablet-portrait) {
  .row {
    display: grid;
    gap: var(--spacing-3);
    grid-template-columns: auto;

    &.withSmallSidebar {
      grid-template-columns: repeat(12, 1fr);

      & > *:nth-child(2) {
        grid-column: 1 / 10;
      }

      & > *:nth-child(3) {
        grid-column: 10 / 13;
      }
    }

    &.withLargeSidebar {
      grid-template-columns: repeat(12, 1fr);

      & > *:nth-child(2) {
        grid-column: 1 / 9;
      }

      & > *:nth-child(3) {
        grid-column: 9 / 13;
      }
    }
  }
}

@media only screen and (width >= $tablet-landscape) {
  .row {
    gap: var(--spacing-4);
    grid-template-columns: auto;

    &.withNav {
      grid-template-columns: repeat(12, 1fr);

      & > *:nth-child(1) {
        grid-column: 1 / 3;
      }

      & > *:nth-child(2) {
        grid-column: 3 / 13;
      }

      &.withSmallSidebar {
        & > *:nth-child(1) {
          grid-column: 1 / 3;
        }

        & > *:nth-child(2) {
          grid-column: 3 / 10;
        }

        & > *:nth-child(3) {
          grid-column: 10 / 13;
        }
      }

      &.withLargeSidebar {
        & > *:nth-child(1) {
          grid-column: 1 / 3;
        }

        & > *:nth-child(2) {
          grid-column: 3 / 9;
        }

        & > *:nth-child(3) {
          grid-column: 9 / 13;
        }
      }
    }
  }

  .row nav {
    display: block;
  }
}
